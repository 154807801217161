<template>
  <v-container  fluid style="margin-top:130px;">
      <v-container>
      
      
    <v-row fluid>
        <v-col cols="12">
            <center><b >Статистика нарахувань за <br>торгові точки мережі</b></center>
        </v-col>
    </v-row>
    <v-row fluid>
        <v-col cols="12">
          <b ><font style="color:green">Підтверджено</font></b><br>
                      <b ><font style="color:#2f49ff">Очікує підтвердження</font></b><br>
                      <b ><font style="color:gray">Відсутній користувач</font></b><br>
                      <font-awesome-icon icon="fa fa-camera "  aria-hidden="true"/> — фото вітрини <br>
                      <font-awesome-icon icon="fa fa-warehouse" aria-hidden="true"/> — сканування запасу <br>
                      <font-awesome-icon icon="fa fa-question-circle " aria-hidden="true"/> — тести <br>
                      <font-awesome-icon icon="fa fa-qrcode "  aria-hidden="true"/> — збільшення запасу <br>
                      <font-awesome-icon icon="fa fa-square " class="icon-wrong"  aria-hidden="true"/> — помилка виконання / не виконувалось <br>
                      <font-awesome-icon icon="fa fa-square " class="icon-correct"  aria-hidden="true"/> — виконано вірно <br>
            
        </v-col>
    </v-row>

    <v-row fluid>
        <v-col cols="12">
              <v-expansion-panels
        :accordion="accordion"
        :popout="popout"
        :inset="inset"
        :multiple="multiple"
        :focusable="focusable"
        :disabled="disabled"
        :readonly="readonly"
        :flat="flat"
        :hover="hover"
        :tile="tile"
        v-model="stat_model"
      >
       <v-expansion-panel
          v-for="(statm,i) in stat"
          :key="i"
          
        >
          <v-expansion-panel-header  style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;"><b>{{statm.month}}</b></v-expansion-panel-header>
          <v-expansion-panel-content style="padding:15px;" >
              <v-row
              
              >
                <v-col cols="6"><b>Точка</b></v-col>
                <v-col cols="3"><center><b>%</b></center></v-col>
                <v-col cols="3"><center><b>UAH</b></center></v-col>
              </v-row>
            <v-container
            class="ma-0 pa-0 mb-3"
            v-for="(cd,b) in statm.c_d"
                :key="b"
               :style="'color:'+c_state[cd.state]" >
              <v-row
             
              >
                <v-col cols="6">{{cd.name}}</v-col>
                <v-col cols="3"><center>{{cd.res_percent}}</center></v-col>
                <v-col cols="3"><center>{{cd.res_summ}}</center></v-col>
              
              
              </v-row>  
              <v-row  class="mt-n4">
                <v-col cols="6">
                  Перший період:&nbsp;
                </v-col>
                <v-col cols="6" class="pt-4">                
                  <div style="display: flex;"><font-awesome-icon icon="fa fa-camera " :class="cd.exec_result&&cd.exec_result.photo1==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-warehouse " :class="cd.exec_result&&cd.exec_result.sku1==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t11==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t12==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t13==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t14==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-qrcode " :class="cd.exec_result&&cd.exec_result.dmx1==1?'icon-correct':'icon-wrong'" aria-hidden="true"/></div>                  
                </v-col>
              </v-row>
              <v-row  class="mt-n6 mb-0">
                <v-col cols="6">
                  Другий період:&nbsp;
                </v-col>
                <v-col cols="6" class="pt-4">                                  
                  <div style="display: flex;"><font-awesome-icon icon="fa fa-camera " :class="cd.exec_result&&cd.exec_result.photo2==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-warehouse " :class="cd.exec_result&&cd.exec_result.sku2==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t21==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t22==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t23==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t24==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-qrcode " :class="cd.exec_result&&cd.exec_result.dmx2==1?'icon-correct':'icon-wrong'" aria-hidden="true"/></div>              
                </v-col>
              </v-row>
              <hr>
            </v-container>

          </v-expansion-panel-content>
        </v-expansion-panel>
              </v-expansion-panels>
      </v-col>
    </v-row>

      
      </v-container>

 
      <v-dialog v-model="faq">
      <v-card>
        <v-card-title class=""  style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_faq')}}  
        </v-card-title>
       
        <v-card-text>
          <v-expansion-panels
        :accordion="accordion"
        :popout="popout"
        :inset="inset"
        :multiple="multiple"
        :focusable="focusable"
        :disabled="disabled"
        :readonly="readonly"
        :flat="flat"
        :hover="hover"
        :tile="tile"
      >
        <v-expansion-panel
          v-for="(faq,i) in faqs"
          :key="i"
        >
          <v-expansion-panel-header><b>{{faq.header}}</b></v-expansion-panel-header>
          <v-expansion-panel-content v-html="faq.text" style="padding:15px;">
            
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="faq = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="write">
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_write')}}  
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
             <v-textarea
            outlined
            name="input-7-4"
            label=""
            value="Питання"
            v-model=req_tp
          ></v-textarea>

          <v-btn primary large block type="submit" @click="save_request"  class="primary_button">Надіслати</v-btn>
         </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="write = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="write_tp">
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_contact')}}  
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
             <v-textarea
            outlined
            name="input-7-4"
            label=""
            value="Питання"
            v-model=req_tp_tp
          ></v-textarea>

          <v-btn primary large block type="submit" @click="save_request_tp"  class="primary_button">Надіслати</v-btn>
         </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="write_tp = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
</template>

<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    faq:false,
    write:false,
    write_tp:false,
    contact:false,
    c_state:{"-1":'gray',"1":"green","0":"#2f49ff"},
    stat:[
        // {month:'Вересень 2021',c_d:[{name:'Кривий Ріг,	Горняцький мкр 44',res:'90',state:'1'},
        //                             {name:'Кривий Ріг,	Спаська 20',res:'60',state:'-1'},
        //                             {name:'Кривий Ріг,	Волгоградская 4',res:'50',state:'0'},
        //                             {name:'Кривий Ріг,	Покровська вул. 32',res:'78',state:'1'},
        //                             {name:'Кривий Ріг,	Медична вул. 19',res:'20',state:'0'},
        //                             {name:'Кривий Ріг,	200-річчя Кривого Рогу вул. 30А',res:'30',state:'1'},]}
    ],
   
        accordion: false,
    popout: false,
    inset: false,
    multiple: true,
    disabled: false,
    readonly: false,
    focusable: false,
    flat: false,
    hover: false,
    tile: false, 
    stat_model:[0],
    req_tp:'',
    req_tp_tp:'',
        apiurl:API_LOCATION,
  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    },
      title() {
        return this.$t('title')
      },
      faqs(){
        
        return[
          
          {'header':this.$t('q1'),'text':this.$t('a1')},
          {'header':this.$t('q2'),'text':this.$t('a2')},
          {'header':this.$t('q3'),'text':this.$t('a3')},
          {'header':this.$t('q4'),'text':this.$t('a4')},
          {'header':this.$t('q5'),'text':this.$t('a5')},
          {'header':this.$t('q6'),'text':this.$t('a6')},
          {'header':this.$t('q7'),'text':this.$t('a7')},
          {'header':this.$t('q8'),'text':this.$t('a8')},
          {'header':this.$t('q9'),'text':this.$t('a9')},
          {'header':this.$t('q10'),'text':this.$t('a10')}
        ]},
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {
     
  },
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
    this.lpr_stat();
  },
  methods:{
        save_request: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create', 'question':this.req_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            alert('Запит надіслано!');
            this.write=false;
            this.req_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },

        save_request_tp: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create_tp', 'question':this.req_tp_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            alert('Запит надіслано!');
            this.write_tp=false;
            this.req_tp_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },

      lpr_stat: function(){
      axios
      .post(
        API_LOCATION,
        {
          data: "component_data",
          action: "lpr_stat",
          
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        // eslint-disable-next-line no-unused-vars
        console.log(resp.data);
        
        this.stat=resp.data;
        
      });
    }

    }
};
</script>


<i18n>
{
  
  "ua":{
      "title":"Статистика",
      "btn1":"Надіслати повідомлення <br>до служби підтримки",
      "btn2":"Відповіді на часті питання",
      "btn3":"Надіслати повідомлення <br>торговому представнику",
      "close":"Закрити",
      "h_write":"Надіслати повідомлення до служби підтримки",
      "h_contact":"Надіслати повідомлення торговому представнику",
      "h_faq":"Відповіді на часті питання",
      "q1":"Чи можу я змінити номер телефону для участі у програмі?",
      "a1":"Так, це можливо. Зверніться, будь ласка, до Вашого Торгового Представника.",

      "q2":"Яку максимальну кількість балів я можу отримати за місяць?",
      "a2":"Максимальна кількість балів, яку можна отримати, залежить від типу Вашого контракту: <br>К300 – 300 балів, <br>К400 – 400 балів,<br>К500 – 500 балів. <br>Виконання екстра завдань дає можливість заробити додаткові бали в  доповнення до стандартної кількості балів контракту.",
      
      "q3":"Я зробив(-ла) помилку при виборі асортименту для участі. Чи можу я його змінити?",
      "a3":"Зміна асортименту для участі доступна раз на один квартал. Для уточнення інформації зверніться, будь ласка, до Вашого Торгового Представника.",

      "q4":"Чи зберігаються на наступний місяць мої накопичені бали?",
      "a4":"Так, зберігаються.",

      "q5":"Мені не нарахували бали за завдання «Фото вітрини» та «Мій товарний запас», у чому причина?",
      "a5":"Усі фото перевіряються вручну модератором, тому модерація може займати декілька днів. Точну інформацію Ви можете дізнатись у свого Торгового Представника.",

      "q6":"Які критерії враховуються при перевірці завдання «Фото вітрини»?",
      "a6":"Критеріями перевірки є  наявність усіх SKU, (відповідно до обраного асортименту) та правильність їх викладки.",

      "q7":"Якщо я беру участь в програмі як мережева торгова точка, скільки SKU має бути на фото вітрини?",
      "a7":"На фото вітрини має бути 32 SKU із актуального асортименту програми (актуальний асортимент уточнюйте у Вашого Торгового Представника).",

      "q8":"Які критерії враховуються при перевірці завдання «Мій товарний запас»?",
      "a8":"Критерієм перевірки є наявність на фото блоку кожного SKU із списку, наведеного у завданні, з нанесеним унікальним кодом.",

      "q9":"Я ввів(-ла) неправильний номер картки Fishka, чи можна його змінити?",
      "a9":"Так, змінити номер карти Fishka можна. Для цього достатньо звернутися у службу тех. підтримки iTeam.",

      "q10":"Як довго чекати на замовлені емоції?",
      "a10":"Поповнення мобільного рахунку та нарахування балів Fishka відбувається одразу (15-20 хвилин) автоматично, замовлені електронні сертифікати «Сільпо», «Єва» та «Розетка» з'являються у застосунку одразу."


      

  }
}
</i18n>
<style type="text/css">
.icon-correct {
  color: green;
}
.icon-wrong {
  color: red;
}
  </style>